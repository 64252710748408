<template>
    <div>
        <ValidationObserver ref="form">
            <form>
                <div class="client-demo-card-header">
                    <h3>Assigned Dashboard</h3>
                </div>
                <div class="client-demo client-profile-form general px-4">
                    <div class="mt-3">
                        <span class="d-flex gap-1 my-3">
                            <h3>Configure for Old Apk versions</h3>
                            <span class="add-new-app-label" style="color: #848484"
                                >(Supports versions upto Kiosk: 1.15.0 & MHH: 1.18.0)</span
                            >
                        </span>
                        <div class="d-flex flex-column w-30 pb-4">
                            <label for="clientDemographicsChosenTheme" class="add-new-app-label"
                                >Choose Dashboard</label
                            >
                            <Multiselect
                                id="clientDemographicsChosenTheme"
                                track-by="id"
                                label="value"
                                class="position-relative w-100"
                                placeholder="Select Dashboard"
                                v-model="dashboardUsed"
                                :options="allDashboardList"
                                :searchable="true"
                                :show-labels="false"
                                :disabled="!userIsSuperAdmin"
                                @input="triggerProfileInfoFormChange($event, 'dashboardUsed')"
                            />
                        </div>
                    </div>
                    <div class="mt-2">
                        <h3 class="my-3">Configure for New Apk versions</h3>
                        <div class="d-flex flex-wrap mt-2 mb-4 w-100 pb-4">
                            <div class="mb-3 d-flex flex-column justify-content-center w-30 mr-3">
                                <label for="kiosk" class="add-new-app-label">Kiosk</label>
                                <Multiselect
                                    id="kiosk"
                                    track-by="id"
                                    label="value"
                                    class="position-relative w-100"
                                    placeholder="Select Dashboard"
                                    v-model="kiosk_dashboard_used"
                                    :options="kioskDashboardList"
                                    :searchable="true"
                                    :show-labels="false"
                                    :disabled="!userIsSuperAdmin"
                                    @input="triggerProfileInfoFormChange($event, 'kiosk_dashboard_used')"
                                />
                            </div>
                            <div class="mb-3 d-flex flex-column justify-content-center w-30 mx-3">
                                <label for="mhh" class="add-new-app-label">Mobex-health-hub</label>
                                <Multiselect
                                    id="mhh"
                                    track-by="id"
                                    label="value"
                                    class="position-relative w-100"
                                    placeholder="Select Dashboard"
                                    v-model="mhh_dashboard_used"
                                    :options="mhhDashbordList"
                                    :searchable="true"
                                    :show-labels="false"
                                    :disabled="!userIsSuperAdmin"
                                    @input="triggerProfileInfoFormChange($event, 'mhh_dashboard_used')"
                                />
                            </div>
                            <div class="mb-3 d-flex flex-column justify-content-center w-30 ml-3">
                                <label for="fc" class="add-new-app-label">Family-connect</label>
                                <Multiselect
                                    id="fc"
                                    track-by="id"
                                    label="value"
                                    class="position-relative w-100"
                                    placeholder="Select Dashboard"
                                    v-model="family_connect_dashboard_used"
                                    :options="fcDashbordList"
                                    :searchable="true"
                                    :show-labels="false"
                                    :disabled="!userIsSuperAdmin"
                                    @input="triggerProfileInfoFormChange($event, 'family_connect_dashboard_used')"
                                />
                            </div>
                            <div v-if="isMainCompany" class="mb-3 d-flex flex-column justify-content-center w-30 mr-3">
                                <label for="star-kid" class="add-new-app-label">STAR Kids</label>
                                <Multiselect
                                    id="star-kid"
                                    track-by="id"
                                    label="value"
                                    class="position-relative w-100"
                                    placeholder="Select Dashboard"
                                    v-model="star_kid_dashboard_used"
                                    :options="starkidDashboardList"
                                    :searchable="true"
                                    :show-labels="false"
                                    :disabled="!userIsSuperAdmin"
                                    @input="triggerProfileInfoFormChange($event, 'star_kid_dashboard_used')"
                                />
                            </div>
                            <div v-if="isMainCompany" class="mb-3 d-flex flex-column justify-content-center w-30 mx-3">
                                <label for="mhp" class="add-new-app-label">Mobex-health-plus</label>
                                <Multiselect
                                    id="mhp"
                                    track-by="id"
                                    label="value"
                                    class="position-relative w-100"
                                    placeholder="Select Dashboard"
                                    v-model="mobex_plus_dashboard_used"
                                    :options="mhpDashboardList"
                                    :searchable="true"
                                    :show-labels="false"
                                    :disabled="!userIsSuperAdmin"
                                    @input="triggerProfileInfoFormChange($event, 'mobex_plus_dashboard_used')"
                                />
                            </div>
                            <div class="mb-3 d-flex flex-column justify-content-center w-30 ml-3">
                                <label for="mhp" class="add-new-app-label">Mobex-health-home</label>
                                <Multiselect
                                    id="mhhh"
                                    track-by="id"
                                    label="value"
                                    class="position-relative w-100"
                                    placeholder="Select Dashboard"
                                    v-model="mobex_health_home_dashboard_used"
                                    :options="healthHomeDashboardList"
                                    :searchable="true"
                                    :show-labels="false"
                                    :disabled="!userIsSuperAdmin"
                                    @input="triggerProfileInfoFormChange($event, 'mobex_health_home_dashboard_used')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import { clients } from '@/util/apiRequests';
    import Input from '@/components/general/validatedInputs/Input.vue';
    import { dashboardBuilder } from '@/util/apiRequests';
    import { Roles } from '@/util/globalConstants';
    import { contactDetails } from '@/util/helpers/graphql/common.graphql.js';
    import { sendReminderDashboardChangeNotification } from '@/util/helpers/graphql/activityReminders.graphql.js';
    export default {
        name: 'ClientDemographicsDashboardBuilder',
        components: { ValidationObserver, Input },
        props: {
            clientId: {
                type: [Number, String],
                default: 0,
            },
            clientDashboardInfo: {
                type: Object,
            },
        },
        computed: {
            userIsSuperAdmin() {
                if (this.$store.state.user.role_id == Roles.SUPER_ADMIN) {
                    return true;
                } else return false;
            },
            isMainCompany() {
                return Number(process.env.VUE_APP_mobex_plus_company_id) === this.$store.state.user.company_id;
            },
        },
        data() {
            return {
                Roles,
                isProfileInfoFormChanged: false,
                clientContactDetails: null,
                clientData: null,
                allDashboardList: [],
                kioskDashboardList: [],
                mhhDashbordList: [],
                fcDashbordList: [],
                starkidDashboardList: [],
                mhpDashboardList: [],
                healthHomeDashboardList: [],
                dashboardUsed: '',
                kiosk_dashboard_used: '',
                mhh_dashboard_used: '',
                family_connect_dashboard_used: '',
                star_kid_dashboard_used: '',
                mobex_plus_dashboard_used: '',
                mobex_health_home_dashboard_used: '',
                dashboardInfo: {},
            };
        },
        methods: {
            async getAndSetClientProfileInfo() {
                this.dashboardInfo = { ...this.clientDashboardInfo };

                const {
                    dashboardUsed,
                    kiosk_dashboard_used,
                    mhh_dashboard_used,
                    family_connect_dashboard_used,
                    star_kid_dashboard_used,
                    mobex_plus_dashboard_used,
                    mobex_health_home_dashboard_used,
                } = this.dashboardInfo;

                if (dashboardUsed) {
                    this.dashboardUsed = this.allDashboardList.find((dashboard) => dashboard.id == dashboardUsed);
                }
                if (kiosk_dashboard_used) {
                    this.kiosk_dashboard_used = this.kioskDashboardList.find(
                        (dashboard) => dashboard.id == kiosk_dashboard_used
                    );
                }
                if (mhh_dashboard_used) {
                    this.mhh_dashboard_used = this.mhhDashbordList.find(
                        (dashboard) => dashboard.id == mhh_dashboard_used
                    );
                }
                if (family_connect_dashboard_used) {
                    this.family_connect_dashboard_used = this.fcDashbordList.find(
                        (dashboard) => dashboard.id == family_connect_dashboard_used
                    );
                }
                if (star_kid_dashboard_used) {
                    this.star_kid_dashboard_used = this.starkidDashboardList.find(
                        (dashboard) => dashboard.id == star_kid_dashboard_used
                    );
                }
                if (mobex_plus_dashboard_used) {
                    this.mobex_plus_dashboard_used = this.mhpDashboardList.find(
                        (dashboard) => dashboard.id == mobex_plus_dashboard_used
                    );
                }
                if (mobex_health_home_dashboard_used) {
                    this.mobex_health_home_dashboard_used = this.healthHomeDashboardList.find(
                        (dashboard) => dashboard.id == mobex_health_home_dashboard_used
                    );
                }
            },
            async getClient() {
                try {
                    const res = await this.$api.get(clients.getClient(this.$route.params?.client_id));
                    this.clientData = res.data?.[0];
                    return true;
                } catch (error) {
                    this.$toasted.error(error);
                }
            },
            async getContactDetails() {
                try {
                    const temp = await this.getClient();
                    if (temp) {
                        const email = this.clientData.email;
                        const seniorContactDetails = await contactDetails(email);
                        this.clientContactDetails = {
                            familyId: seniorContactDetails.familyId,
                            contactId: seniorContactDetails.contactId,
                        };
                    }
                } catch (e) {
                    this.$toasted.error('Error while fetching Contact Details');
                }
            },
            async sendReminderDashboardChangeNotification() {
                try {
                    await sendReminderDashboardChangeNotification(
                        this.clientContactDetails.familyId,
                        this.clientContactDetails.contactId
                    );
                } catch (err) {
                    this.$toasted.error('Failed to send Activity Reminder Notification');
                }
            },
            async fetchDashboardList() {
                try {
                    let result = await this.$api.get(dashboardBuilder.getTemplateList('completed'));
                    if (result?.data?.templateMetaList) {
                        this.allDashboardList = result.data.templateMetaList.map((item) => {
                            return {
                                id: item.id,
                                value: item.dashboardName,
                                application: item.application,
                            };
                        });
                        this.kioskDashboardList = this.allDashboardList.filter(
                            (dashboard) => dashboard.application === 'kiosk'
                        );
                        this.mhhDashbordList = this.allDashboardList.filter(
                            (dashboard) => dashboard.application === 'mobex-health-hub'
                        );
                        this.fcDashbordList = this.allDashboardList.filter(
                            (dashboard) => dashboard.application === 'family-connect'
                        );
                        this.starkidDashboardList = this.allDashboardList.filter(
                            (dashboard) => dashboard.application === 'star-kid'
                        );
                        this.mhpDashboardList = this.allDashboardList.filter(
                            (dashboard) => dashboard.application === 'mobex-health-plus'
                        );
                        this.healthHomeDashboardList = this.allDashboardList.filter(
                            (dashboard) => dashboard.application === 'mobex-health-home'
                        );
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            triggerProfileInfoFormChange(val, field) {
                this.dashboardInfo[field] = val?.id ?? null;
                if (val) {
                    if (val.application === 'kiosk') {
                        this.kiosk_dashboard_used = { ...val };
                    } else if (val.application === 'mobex-health-hub') {
                        this.mhh_dashboard_used = { ...val };
                    } else if (val.application === 'family-connect') {
                        this.family_connect_dashboard_used = { ...val };
                    } else if (val.application === 'star-kid') {
                        this.star_kid_dashboard_used = { ...val };
                    } else if (val.application === 'mobex-health-plus') {
                        this.mobex_plus_dashboard_used = { ...val };
                    } else if (val.application === 'mobex-health-home') {
                        this.mobex_health_home_dashboard_used = { ...val };
                    }
                }
                this.$emit('handleDashboardInfo', val, field);
                this.isProfileInfoFormChanged = true;
            },
        },
        async created() {
            await this.fetchDashboardList();
            await this.getAndSetClientProfileInfo();
            await this.getContactDetails();
        },
        watch: {
            clientDashboardInfo: {
                async handler() {
                    await this.getAndSetClientProfileInfo();
                    this.$emit('clientProfileInfoChange', { context: this });
                },
                deep: true,
            },
        },
    };
</script>
